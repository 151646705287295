import Department from './_blessed/store/entities/departments/model';

export const BASE_URL = '/hub';
export const PORT_OF_LA_ADDRESS = '2500 Navy Way, San Pedro, CA 90731';
export const dbDateFormat = 'YYYY-MM-DD';
export const drayAllianceDepartmentUUID = 'd21616a9-0896-7ca5-10ca-5c9ac4215f93';
export const BOT_USER_UUID = '12deafc8-cc8e-46e5-ad22-1e1badf720c2';

export const enableFeatures = {
  LPN: true,
  currentLocationFilter: false
};

export const onboardingAttachmentTypeUIDropdownOptions = [
  { value: 'cdl', label: 'CDL' },
  { value: 'w9Image', label: 'W9' },
  { value: 'authorityLetterImage', label: 'Authority Letter' },
  { value: 'insuranceImage', label: 'Insurance' }
];

export const globalRateDepartment = {
  uuid: 'global-rate',
  label: 'Global',
  name: 'Global'
} as unknown as Department;

export const timePeriodUIDropdownOptions = [
  { value: 'hours', label: 'Hours' },
  { value: 'days', label: 'Days' },
  { value: null, label: 'No Grace Period' }
];

export const timePeriodTypeToUIMappings = {
  hours: 'Hours',
  days: 'Days',
  null: 'No Grace Period'
};

export const gracePeriodTypeUIDropdownOptions = [
  { value: 'drop_only', label: 'Drop Only' },
  { value: 'droppick', label: 'Drop & Pick' },
  { value: 'live', label: 'Live Load/Unload' }
];

export const gracePeriodTypeToUIMappings = {
  drop_only: 'Drop Only',
  droppick: 'Drop & Pick',
  live: 'Live Load/Unload'
};

export const chassisFeeFreeAmountTypeUIDropdownOptions = [
  { value: 'no_free_amount', label: 'No Free Amount' },
  { value: 'calendar_daily', label: 'Calendar Daily' },
  { value: 'forever_free', label: 'Forever Free' },
  { value: 'customer_pay', label: 'Customer Pay' }
];

export const chassisFeeFreeAmountTypeToUIMappings = {
  no_free_amount: 'No Free Amount',
  calendar_daily: 'Calendar Daily',
  business_daily: 'Business Daily',
  forever_free: 'Forever Free',
  customer_pay: 'Customer Pay'
};

export const chassisFeeAccessorialPercOfUIDropdownOptions = [
  { value: 'drayage', label: 'Drayage Fee' },
  { value: 'fsc', label: 'FSC' },
  { value: 'drayage_fsc', label: 'Drayage + FSC' },
  { value: 'other', label: 'All the other accessorial fees' }
];

export const chassisFeeAccessorialPercOfToUIMappings = {
  drayage: 'Drayage Fee',
  fsc: 'FSC',
  drayage_fsc: 'Drayage + FSC',
  other: 'All the other accessorial fees'
};

export const chassisFeePeriodUIDropdownOptions = [
  { value: 'calendar_daily', label: 'Calendar Daily' },
  { value: 'unit', label: 'Unit' }
];

export const chassisFeePeriodsToUIMappings = {
  calendar_daily: 'Calendar Daily',
  business_daily: 'Business Daily',
  unit: 'Unit'
};

export const containerSizes = {
  TWENTY_SD: '20SD',
  TWENTY_SDR: '20SDR',
  FOURTY_SD: '40SD',
  FOURTY_SDR: '40SDR',
  FOURTY_HQ: '40HQ',
  FOURTY_HQR: '40HQR',
  FOURTYFIVE_SD: '45SD',
  FOURTYFIVE_SDR: '45SDR',
  FOURTYFIVE_HQ: '45HQ',
  FOURTYFIVE_HQR: '45HQR',
  UNKNOWN: 'Unknown'
};

export const containerSizeToUIMappings = Object.values(containerSizes).reduce((acc: Record<string, any>, curr) => {
  acc[curr] = curr;
  return acc;
}, {});

export const containerSizeUIDropdownOptions = Object.values(containerSizes).map((size) => ({
  value: size,
  label: size
}));

export const terminalStatusToUIMappings = {
  waiting_discharge: 'Waiting Discharge',
  need_check_with_terminal: 'Need Check With Terminal',
  might_off_dock: 'Might Move to Off Dock',
  customs_exam: 'Customs Exam',
  discharged: 'Discharged',
  off_dock: 'Off Dock To Shippers',
  empty_load_ready: 'Empty/Load Ready',
  treet_turn: 'Street Turn'
};

export const dropOrLiveValues = {
  dropOnly: 'drop_only',
  droppick: 'droppick',
  live: 'live'
};

export const dropOrLiveToUIMappings = {
  [dropOrLiveValues.dropOnly]: 'Drop w/o Backhaul',
  [dropOrLiveValues.droppick]: 'Drop & Pick',
  [dropOrLiveValues.live]: 'Live',
  single: 'Single Trip',
  pending: 'Pending'
};

// used on order right sidebar
export const dropOrLiveUIDropdownOptions = [
  { value: dropOrLiveValues.dropOnly, label: dropOrLiveToUIMappings[dropOrLiveValues.dropOnly] },
  { value: dropOrLiveValues.droppick, label: dropOrLiveToUIMappings[dropOrLiveValues.droppick] },
  { value: dropOrLiveValues.live, label: dropOrLiveToUIMappings[dropOrLiveValues.live] }
];

export const importExportValues = {
  import: 'import',
  export: 'export',
  grayPoolImport: 'grayPoolImport'
};

export const importExportUIMappings = {
  [importExportValues.import]: 'Import',
  [importExportValues.export]: 'Export',
  [importExportValues.grayPoolImport]: 'Gray Pool Import'
};

export const importExportUIDropdownOptions = [
  { value: importExportValues.import, label: importExportUIMappings.import },
  { value: importExportValues.export, label: importExportUIMappings.export },
  { value: importExportValues.grayPoolImport, label: importExportUIMappings.grayPoolImport }
];

export const stopLocationTypeUIDropdownOptions = [
  { value: 'terminal', label: 'Terminal' },
  { value: 'rail_yard', label: 'Rail Yard' },
  { value: 'yard', label: 'Yard' },
  { value: 'consignee', label: 'Warehouse' }
];

export const transportationModeUIDropdownOptions = [
  { value: 'ocean', label: 'Ocean' },
  { value: 'rail', label: 'Rail' }
];

export const promotionUIDropdownOptions = [
  { value: 'promotion_only', label: 'Manual Promotion' },
  { value: 'system_promotion', label: 'System Promotion' },
  { value: 'no_promotion', label: 'No Promotion' }
];

export const promotionTypeUIMappings = {
  promotion_only: 'Manual Promotion',
  no_promotion: 'No Promotion',
  system_promotion: 'System Promotion'
};

export const stopTypes = ['consignee', 'terminal', 'container_depot', 'yard', 'rail_yard'];
export const stopTypesToUIMappings = {
  consignee: 'Consignee',
  terminal: 'Terminal',
  container_depot: 'Container Depot',
  yard: 'Yard',
  rail_yard: 'Rail Yard'
};
export const stopTypesUIDropdownOptions = [
  { value: 'consignee', label: stopTypesToUIMappings.consignee },
  { value: 'terminal', label: stopTypesToUIMappings.terminal },
  { value: 'container_depot', label: stopTypesToUIMappings.container_depot },
  { value: 'yard', label: stopTypesToUIMappings.yard },
  { value: 'rail_yard', label: stopTypesToUIMappings.rail_yard }
];

export const departmentTypesToUIMappings = {
  chassis_pool: 'Chassis Pool',
  chassis_yard: 'Chassis Yard',
  carrier: 'Carrier',
  consignee: 'Consignee',
  customer: 'Customer',
  admin: 'Admin',
  terminal: 'Terminal',
  container_depot: 'Container Depot',
  yard: 'Yard',
  rail_yard: 'Rail',
  shipping_line: 'Shipping Line'
};

export const departmentRightSidebarShipperTypesToUIMappings = {
  bco: 'BCO',
  ff: 'FF'
};

export const departmentRightSidebarTypesToUIMappings = {
  chassis_pool: 'Chassis Pool',
  chassis_yard: 'Chassis Yard',
  carrier: 'Carrier',
  consignee: 'Consignee',
  customer: 'Customer',
  admin: 'Admin',
  yard: 'Yard',
  rail_yard: 'Rail',
  terminal: 'Terminal',
  shipping_line: 'Shipping Line'
};

export const departmentRightSidebarTypesUIDropdownOptions = [
  { value: 'chassis_pool', label: departmentRightSidebarTypesToUIMappings.chassis_pool },
  { value: 'chassis_yard', label: departmentRightSidebarTypesToUIMappings.chassis_yard },
  { value: 'carrier', label: departmentRightSidebarTypesToUIMappings.carrier },
  { value: 'consignee', label: departmentRightSidebarTypesToUIMappings.consignee },
  { value: 'customer', label: departmentRightSidebarTypesToUIMappings.customer },
  { value: 'admin', label: departmentRightSidebarTypesToUIMappings.admin },
  { value: 'yard', label: departmentRightSidebarTypesToUIMappings.yard },
  { value: 'rail_yard', label: departmentRightSidebarTypesToUIMappings.rail_yard },
  { value: 'terminal', label: departmentRightSidebarTypesToUIMappings.terminal },
  { value: 'shipping_line', label: departmentRightSidebarTypesToUIMappings.shipping_line }
];

export const departmentRightSidebarShipperTypesUIDropdownOptions = [
  { value: 'bco', label: departmentRightSidebarShipperTypesToUIMappings.bco },
  { value: 'ff', label: departmentRightSidebarShipperTypesToUIMappings.ff }
];

export const usStatesUIDropdownOptions = [
  { value: 'Alabama', label: 'Alabama' },
  { value: 'Alaska', label: 'Alaska' },
  { value: 'American Samoa', label: 'American Samoa' },
  { value: 'Arizona', label: 'Arizona' },
  { value: 'Arkansas', label: 'Arkansas' },
  { value: 'California', label: 'California' },
  { value: 'Colorado', label: 'Colorado' },
  { value: 'Connecticut', label: 'Connecticut' },
  { value: 'Delaware', label: 'Delaware' },
  { value: 'District Of Columbia', label: 'District Of Columbia' },
  { value: 'Federated States Of Micronesia', label: 'Federated States Of Micronesia' },
  { value: 'Florida', label: 'Florida' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Guam', label: 'Guam' },
  { value: 'Hawaii', label: 'Hawaii' },
  { value: 'Idaho', label: 'Idaho' },
  { value: 'Illinois', label: 'Illinois' },
  { value: 'Indiana', label: 'Indiana' },
  { value: 'Iowa', label: 'Iowa' },
  { value: 'Kansas', label: 'Kansas' },
  { value: 'Kentucky', label: 'Kentucky' },
  { value: 'Louisiana', label: 'Louisiana' },
  { value: 'Maine', label: 'Maine' },
  { value: 'Marshall Islands', label: 'Marshall Islands' },
  { value: 'Maryland', label: 'Maryland' },
  { value: 'Massachusetts', label: 'Massachusetts' },
  { value: 'Michigan', label: 'Michigan' },
  { value: 'Minnesota', label: 'Minnesota' },
  { value: 'Mississippi', label: 'Mississippi' },
  { value: 'Missouri', label: 'Missouri' },
  { value: 'Montana', label: 'Montana' },
  { value: 'Nebraska', label: 'Nebraska' },
  { value: 'Nevada', label: 'Nevada' },
  { value: 'New Hampshire', label: 'New Hampshire' },
  { value: 'New Jersey', label: 'New Jersey' },
  { value: 'New Mexico', label: 'New Mexico' },
  { value: 'New York', label: 'New York' },
  { value: 'North Carolina', label: 'North Carolina' },
  { value: 'North Dakota', label: 'North Dakota' },
  { value: 'Northern Mariana Islands', label: 'Northern Mariana Islands' },
  { value: 'Ohio', label: 'Ohio' },
  { value: 'Oklahoma', label: 'Oklahoma' },
  { value: 'Oregon', label: 'Oregon' },
  { value: 'Palau', label: 'Palau' },
  { value: 'Pennsylvania', label: 'Pennsylvania' },
  { value: 'Puerto Rico', label: 'Puerto Rico' },
  { value: 'Rhode Island', label: 'Rhode Island' },
  { value: 'South Carolina', label: 'South Carolina' },
  { value: 'South Dakota', label: 'South Dakota' },
  { value: 'Tennessee', label: 'Tennessee' },
  { value: 'Texas', label: 'Texas' },
  { value: 'Utah', label: 'Utah' },
  { value: 'Vermont', label: 'Vermont' },
  { value: 'Virgin Islands', label: 'Virgin Islands' },
  { value: 'Virginia', label: 'Virginia' },
  { value: 'Washington', label: 'Washington' },
  { value: 'West Virginia', label: 'West Virginia' },
  { value: 'Wisconsin', label: 'Wisconsin' },
  { value: 'Wyoming', label: 'Wyoming' }
];

export const visibility = {
  notVisible: 'not_visible',
  marketplace: 'marketplace',
  backhaul: 'backhaul'
};

export const pseudoLegStatuses = {
  backhaulReady: 'backhaul_ready'
};

export const legStatuses = {
  paperworkReady: 'paperwork_ready', // Reserved
  readyForPickup: 'ready_for_pickup',
  driverPaid: 'driver_paid', // Reserved
  driverAssigned: 'driver_assigned',
  inTransit: 'in_transit',
  legCreated: 'leg_created',
  billed: 'billed', // Reserved
  started: 'started',
  delivered: 'delivered'
};

export const legStatusesOrderMap = {
  [legStatuses.legCreated]: 0,
  [legStatuses.readyForPickup]: 1,
  [legStatuses.driverAssigned]: 2,
  [legStatuses.started]: 3,
  [legStatuses.inTransit]: 4,
  [legStatuses.delivered]: 5,
  [legStatuses.paperworkReady]: 6,
  [legStatuses.billed]: 7,
  [legStatuses.driverPaid]: 8
};

export const legStatusesToUIMappings = {
  [legStatuses.paperworkReady]: 'Paperwork Ready',
  [legStatuses.readyForPickup]: 'Ready For Pickup - On Availability',
  [legStatuses.legCreated]: 'Leg Created',
  [legStatuses.driverPaid]: 'Driver Paid',
  [legStatuses.driverAssigned]: 'Assigned',
  [legStatuses.inTransit]: 'In Transit',
  [legStatuses.billed]: 'Billed',
  [legStatuses.started]: 'Started',
  [legStatuses.delivered]: 'Delivered',
  [pseudoLegStatuses.backhaulReady]: 'Backhaul Ready'
};

export const legStatusesToUIMappingsPreBackhaul = {
  [legStatuses.paperworkReady]: 'Paperwork Ready',
  [legStatuses.readyForPickup]: 'Ready For Pickup',
  [legStatuses.legCreated]: 'Leg Created',
  [legStatuses.driverPaid]: 'Driver Paid',
  [legStatuses.driverAssigned]: 'Assigned',
  [legStatuses.inTransit]: 'In Transit',
  [legStatuses.billed]: 'Billed'
};

export const orderOverweightUIDropdownOptions = [
  { value: false, label: 'Standard' },
  { value: true, label: 'Overweight' }
];
export const legStatusesUIDropdownOptions = [
  { value: legStatuses.legCreated, label: legStatusesToUIMappings[legStatuses.legCreated] },
  { value: pseudoLegStatuses.backhaulReady, label: legStatusesToUIMappings[pseudoLegStatuses.backhaulReady] },
  { value: legStatuses.readyForPickup, label: legStatusesToUIMappings[legStatuses.readyForPickup] },
  { value: legStatuses.driverAssigned, label: legStatusesToUIMappings[legStatuses.driverAssigned] },
  { value: legStatuses.inTransit, label: legStatusesToUIMappings[legStatuses.inTransit] },
  { value: legStatuses.paperworkReady, label: legStatusesToUIMappings[legStatuses.paperworkReady] },
  { value: legStatuses.billed, label: legStatusesToUIMappings[legStatuses.billed] },
  { value: legStatuses.driverPaid, label: legStatusesToUIMappings[legStatuses.driverPaid] }
];

export const legStatusesUIDropdownOptionsPreBackhaul = [
  { value: legStatuses.legCreated, label: legStatusesToUIMappingsPreBackhaul[legStatuses.legCreated] },
  { value: legStatuses.readyForPickup, label: legStatusesToUIMappingsPreBackhaul[legStatuses.readyForPickup] },
  { value: legStatuses.driverAssigned, label: legStatusesToUIMappingsPreBackhaul[legStatuses.driverAssigned] },
  { value: legStatuses.inTransit, label: legStatusesToUIMappingsPreBackhaul[legStatuses.inTransit] },
  { value: legStatuses.paperworkReady, label: legStatusesToUIMappingsPreBackhaul[legStatuses.paperworkReady] },
  { value: legStatuses.billed, label: legStatusesToUIMappingsPreBackhaul[legStatuses.billed] },
  { value: legStatuses.driverPaid, label: legStatusesToUIMappingsPreBackhaul[legStatuses.driverPaid] }
];

export const jobBundleStatuses = {
  readyForPickup: 'ready_for_pickup',
  driverAssigned: 'driver_assigned',
  legCreated: 'leg_created',
  driverPaid: 'driver_paid'
};

export const jobBundleStatusesToUIMappings = {
  [jobBundleStatuses.readyForPickup]: 'Ready For Pickup',
  [jobBundleStatuses.legCreated]: 'Leg Created',
  [jobBundleStatuses.driverAssigned]: 'Assigned',
  [jobBundleStatuses.driverPaid]: 'Driver Paid'
};

export const jobBundleStatusesUIDropdownOptions = [
  { value: jobBundleStatuses.legCreated, label: jobBundleStatusesToUIMappings[jobBundleStatuses.legCreated] },
  { value: jobBundleStatuses.readyForPickup, label: jobBundleStatusesToUIMappings[jobBundleStatuses.readyForPickup] },
  { value: jobBundleStatuses.driverAssigned, label: jobBundleStatusesToUIMappings[jobBundleStatuses.driverAssigned] }
];

export const legTypes = {
  normal: 'normal',
  liveLoad: 'live_load',
  liveUnload: 'live_unload',
  dropOnly: 'drop_only',
  streetTurn: 'street_turn',
  dryRun: 'dry_run'
};

export const legTypesToUIMappings = {
  [legTypes.normal]: 'Normal',
  [legTypes.liveLoad]: 'Live Load',
  [legTypes.liveUnload]: 'Live Unload',
  [legTypes.dropOnly]: 'Drop w/o Backhaul',
  [legTypes.streetTurn]: 'Street Turn',
  [legTypes.dryRun]: 'Dry Run'
};

export const legTypesUIDropdownOptions = [
  { value: legTypes.normal, label: legTypesToUIMappings[legTypes.normal] },
  { value: legTypes.liveLoad, label: legTypesToUIMappings[legTypes.liveLoad] },
  { value: legTypes.liveUnload, label: legTypesToUIMappings[legTypes.liveUnload] },
  { value: legTypes.dropOnly, label: legTypesToUIMappings[legTypes.dropOnly] },
  { value: legTypes.streetTurn, label: legTypesToUIMappings[legTypes.streetTurn] },
  { value: legTypes.dryRun, label: legTypesToUIMappings[legTypes.dryRun] }
];

export const accessorialTypes = {
  adminFee: 'admin_fee',
  drayageCharge: 'drayage_charge',
  fsc: 'fsc',
  bobtailIn: 'bobtail_in',
  bobtailOut: 'bobtail_out',
  bobtailFee: 'bobtail',
  cancellation: 'cancellation',
  chassisFee: 'chassis_fee',
  chassisSplit: 'chassis_split',
  containerDepotWait: 'container_depot_wait',
  customsExam: 'customs_exam',
  deadhead: 'deadhead',
  demurrageFee: 'demurrage_fee',
  detention: 'detention',
  dryRunCustomer: 'dry_run_customer',
  dryRunIngate: 'dry_run_ingate',
  dryRunOutgate: 'dry_run_outgate',
  examFee: 'exam_fee',
  hazmatMisuse: 'hazmat_misuse',
  hazmatSurcharge: 'hazmat_surcharge',
  hotLoadSurcharge: 'hot_load_surcharge',
  lastMinuteSurcharge: 'last_minute_surcharge',
  layover: 'layover',
  loadUnloadWait: 'load_unload_wait',
  lumperServices: 'lumper_services',
  overweight: 'overweight',
  operatorWaitTime: 'operator_wait_time',
  other: 'other',
  perDiemFee: 'per_diem_fee',
  pierPass20: 'pier_pass_20',
  pierPass40: 'pier_pass_40',
  pierPassNotPaidWaitFee: 'pier_pass_not_paid_wait_fee',
  portWait: 'port_wait',
  prePull: 'pre_pull',
  promotion: 'promotion',
  reeferSurcharge: 'reefer_surcharge',
  repair: 'repair',
  residentialDelivery: 'residential_delivery',
  rework: 'rework',
  rushOrder: 'rush_order',
  scale: 'scale',
  specialPermits: 'special_permits',
  stopOffs: 'stop_offs',
  storageFee: 'storage_fee',
  backhaulStorageFee: 'storage_fee_backhaul',
  systemPromotion: 'system_promotion',
  unsafeOrImproperlyLoadedContainer: 'unsafe_or_improperly_loaded_container',
  yardJockeyServices: 'yard_jockey_services',
  yardWait: 'yard_wait'
};

export const pierpassTypes = {
  byCustomer: 'bycustomer',
  byDA: 'byda',
  'N/A': 'na'
};

export const pierpassTypesToUIMappings = {
  [pierpassTypes.byCustomer]: 'Paid by Customer',
  [pierpassTypes.byDA]: 'Paid by DA',
  [pierpassTypes['N/A']]: 'N/A'
};

export const pierpassTypeUIDropdownOptions = [
  { value: pierpassTypes.byCustomer, label: pierpassTypesToUIMappings[pierpassTypes.byCustomer] },
  { value: pierpassTypes.byDA, label: pierpassTypesToUIMappings[pierpassTypes.byDA] },
  { value: pierpassTypes['N/A'], label: pierpassTypesToUIMappings[pierpassTypes['N/A']] }
];

export const terminalRegionUIDropdownOptions = [{ value: 'los_angeles', label: 'Los Angeles' }];

/*
Accessorials
*/

export const adminFeeByTypes = {
  null: 'null',
  percent: 'percent',
  unit: 'unit'
};

export const adminFeeByToUIMappings = {
  [adminFeeByTypes.null]: 'No Admin Fee',
  [adminFeeByTypes.percent]: 'Percent',
  [adminFeeByTypes.unit]: 'Unit'
};

export const adminFeeByUIDropdownOptions = [
  { value: adminFeeByTypes.null, label: adminFeeByToUIMappings[adminFeeByTypes.null!] },
  { value: adminFeeByTypes.percent, label: adminFeeByToUIMappings[adminFeeByTypes.percent] },
  { value: adminFeeByTypes.unit, label: adminFeeByToUIMappings[adminFeeByTypes.unit] }
];

export const freeAmountByTypes = {
  null: 'null',
  hour: 'hour',
  day: 'day'
};

export const freeAmountByToUIMappings = {
  [freeAmountByTypes.null]: 'N/A',
  [freeAmountByTypes.hour]: 'Hour',
  [freeAmountByTypes.day]: 'Day'
};

export const freeAmountByUIDropdownOptions = [
  { value: freeAmountByTypes.null, label: freeAmountByToUIMappings[freeAmountByTypes.null] },
  { value: freeAmountByTypes.day, label: freeAmountByToUIMappings[freeAmountByTypes.day] },
  { value: freeAmountByTypes.hour, label: freeAmountByToUIMappings[freeAmountByTypes.hour] }
];

const chargeByTypes = {
  null: 'null',
  day: 'daily',
  hour: 'hourly',
  quote: 'quote',
  unit: 'unit'
};

const chargeByToUIMappings = {
  [chargeByTypes.null]: '',
  [chargeByTypes.day]: 'Day',
  [chargeByTypes.hour]: 'Hour',
  [chargeByTypes.quote]: 'Quote',
  [chargeByTypes.unit]: 'Unit'
};

const chargeByUIDropdownOptions = [
  { value: chargeByTypes.null, label: chargeByToUIMappings[chargeByTypes.null!] },
  { value: chargeByTypes.day, label: chargeByToUIMappings[chargeByTypes.day] },
  { value: chargeByTypes.hour, label: chargeByToUIMappings[chargeByTypes.hour] },
  { value: chargeByTypes.quote, label: chargeByToUIMappings[chargeByTypes.quote] },
  { value: chargeByTypes.unit, label: chargeByToUIMappings[chargeByTypes.unit] }
];

// -------------------

export const secondaryContainerStatusEnum = {
  APPOINTMENT_SCHEDULING_IN_PROGRESS: 'appointment_scheduling_in_progress',
  AWAITING_DELIVERY_CONFIRMATION: 'awaiting_delivery_confirmation',
  DELIVERED: 'delivered',
  IN_TRANSIT: 'in_transit',
  INVOICE_PAID: 'invoice_paid',
  INVOICE_UNPAID: 'invoice_unpaid',
  NOT_AVAILABLE: 'not_available',
  NOT_DISCHARGED: 'not_discharged',
  ORDER_CANCELLED_BY_DA: 'cancelled_by_da',
  ORDER_CANCELLED_BY_CUSTOMER: 'cancelled_by_customer',
  PENDING_EMPTY_READY_CONFIRMATION: 'pending_empty_ready_confirmation',
  PENDING_LOAD_READY_CONFIRMATION: 'pending_load_ready_confirmation',
  READY_FOR_PICKUP_AT_WAREHOUSE: 'ready_for_pickup_at_warehouse',
  STORED_IN_YARD: 'container_stored_in_yard',
  TERMINAL_APPOINTMENT_SCHEDULED: 'terminal_appointment_scheduled',
  TRACKING_NOT_AVAILABLE: 'tracking_not_available',
  WAREHOUSE_APPOINTMENT_SCHEDULED: 'warehouse_appointment_scheduled'
};

export const secondaryContainerStatuses = [
  secondaryContainerStatusEnum.APPOINTMENT_SCHEDULING_IN_PROGRESS,
  secondaryContainerStatusEnum.AWAITING_DELIVERY_CONFIRMATION,
  secondaryContainerStatusEnum.DELIVERED,
  secondaryContainerStatusEnum.IN_TRANSIT,
  secondaryContainerStatusEnum.INVOICE_PAID,
  secondaryContainerStatusEnum.INVOICE_UNPAID,
  secondaryContainerStatusEnum.NOT_AVAILABLE,
  secondaryContainerStatusEnum.NOT_DISCHARGED,
  secondaryContainerStatusEnum.ORDER_CANCELLED_BY_DA,
  secondaryContainerStatusEnum.ORDER_CANCELLED_BY_CUSTOMER,
  secondaryContainerStatusEnum.PENDING_EMPTY_READY_CONFIRMATION,
  secondaryContainerStatusEnum.PENDING_LOAD_READY_CONFIRMATION,
  secondaryContainerStatusEnum.READY_FOR_PICKUP_AT_WAREHOUSE,
  secondaryContainerStatusEnum.STORED_IN_YARD,
  secondaryContainerStatusEnum.TERMINAL_APPOINTMENT_SCHEDULED,
  secondaryContainerStatusEnum.TRACKING_NOT_AVAILABLE,
  secondaryContainerStatusEnum.WAREHOUSE_APPOINTMENT_SCHEDULED
];

export const secondaryContainerStatusToUIMapping = {
  [secondaryContainerStatusEnum.TRACKING_NOT_AVAILABLE]: () => 'Tracking Not Available For This Order',
  [secondaryContainerStatusEnum.INVOICE_UNPAID]: () => 'Order Invoiced',
  [secondaryContainerStatusEnum.NOT_DISCHARGED]: () => 'Not Discharged',
  [secondaryContainerStatusEnum.WAREHOUSE_APPOINTMENT_SCHEDULED]: () => 'Warehouse Appointment Scheduled',
  [secondaryContainerStatusEnum.APPOINTMENT_SCHEDULING_IN_PROGRESS]: () => 'Appointment Scheduling In Progress',
  [secondaryContainerStatusEnum.READY_FOR_PICKUP_AT_WAREHOUSE]: (params: string[]) =>
    `Ready For Pick-up At ${params[0] || 'Warehouse'}`,
  [secondaryContainerStatusEnum.TERMINAL_APPOINTMENT_SCHEDULED]: () => 'Terminal Appointment Scheduled',
  [secondaryContainerStatusEnum.DELIVERED]: (params: string[]) => `Delivered to ${params[0] || 'Location'}`,
  [secondaryContainerStatusEnum.IN_TRANSIT]: (params: string[]) => `In Transit to ${params[0] || 'Warehouse'}`,
  [secondaryContainerStatusEnum.PENDING_EMPTY_READY_CONFIRMATION]: (params: string[]) =>
    `Pending Empty Ready Confirmation at ${params[0] || 'Warehouse'}`,
  [secondaryContainerStatusEnum.PENDING_LOAD_READY_CONFIRMATION]: (params: string[]) =>
    `Pending Load Ready Confirmation at ${params[0] || 'Warehouse'}`,
  [secondaryContainerStatusEnum.NOT_AVAILABLE]: () => 'Not Available',
  [secondaryContainerStatusEnum.ORDER_CANCELLED_BY_DA]: () => 'Order was cancelled by Dray Alliance',
  [secondaryContainerStatusEnum.ORDER_CANCELLED_BY_CUSTOMER]: () => 'Order was cancelled by Customer',
  [secondaryContainerStatusEnum.INVOICE_PAID]: () => 'Invoice Paid',
  [secondaryContainerStatusEnum.STORED_IN_YARD]: () => 'Container stored in yard',
  [secondaryContainerStatusEnum.AWAITING_DELIVERY_CONFIRMATION]: () => 'Order is awaiting delivery confirmation'
};
export const apChargeByTypes = {
  ...chargeByTypes,
  percentOfRoundTrip: 'percentage_of_round_trip',
  percentOfOneWayTrip: 'percentage_of_one_way_trip'
};

export const apChargeByToUIMappings = {
  ...chargeByToUIMappings,
  [apChargeByTypes.percentOfRoundTrip]: 'Percent of round trip',
  [apChargeByTypes.percentOfOneWayTrip]: 'Percent of one way trip'
};

export const apChargeByUIDropdownOptions = [
  ...chargeByUIDropdownOptions,
  { value: apChargeByTypes.percentOfRoundTrip, label: apChargeByToUIMappings[apChargeByTypes.percentOfRoundTrip] },
  { value: apChargeByTypes.percentOfOneWayTrip, label: apChargeByToUIMappings[apChargeByTypes.percentOfOneWayTrip] }
];

export const arChargeByTypes = {
  ...chargeByTypes,
  percentOfDrayFSC: 'percentage_of_dray_fsc'
};

export const arChargeByToUIMappings = {
  ...chargeByToUIMappings,
  [arChargeByTypes.percentOfDrayFSC]: 'Percent of Dray FSC'
};

export const arChargeByUIDropdownOptions = [
  ...chargeByUIDropdownOptions,
  { value: arChargeByTypes.percentOfDrayFSC, label: arChargeByToUIMappings[arChargeByTypes.percentOfDrayFSC] }
];

export const PER_DIEM_GLOBAL_CUSTOMER_UUID_LOCAL = 'Global';
export const PER_DIEM_GLOBAL_CUSTOMER_UUID_DB = null;
export const PER_DIEM_DEFAULT_FREE_DAYS = null;

export const bundleJoinTypeToUIMappings = {
  dualTrans: 'Dual Trans',
  dropPick: 'Drop & Pick',
  drayPool: 'Dray Pool',
  prePull: 'Pre Pull'
};

export const firstStopTypeToUIMappings = {
  any: 'Any Stop Type',
  yard: 'Yard Type',
  consignee: 'Consignee Type'
};

const GRAY_6 = '#0F1215';
const PRIMARY_COLOR = GRAY_6;

export const ThemeColors = {
  PRIMARY_COLOR,
  GREEN_PRIMARY: '#42C986',
  GREEN_DARKER: '#3AB277',
  YELLOW_PRIMARY: '#FDA936',
  YELLOW_DARKER: '#E6982C',
  BLUE_PRIMARY: '#025EF7',
  BLUE_DARKER: '#014FD1',
  RED_PRIMARY: '#E82459',
  RED_DARKER: '#B1133E',
  RED_PINKER: '#E23564',
  ORANGE_PRIMARY: '#F56A48',
  PURPLE_PRIMARY: '#AB2CD1',

  GRAY_0: '#FFFFFF',
  GRAY_1: '#EFEFF0',
  GRAY_2: '#DDDFE0',
  GRAY_3: '#CBD1D3',
  GRAY_4: '#464A4E',
  GRAY_5: '#92969A',
  GRAY_6,
  GRAY_7: '#9FA2A5',

  LINK_TEXT: '#0060FF',
  SHADOW_COLOR: '#000000'
};

export const [GLOBAL, CONSIGNEE] = ['global', 'consignee'];

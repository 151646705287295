import { PreloadedState, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from './_blessed/store';
import type { RootState } from './reduxProvider';

const sagaMiddleware = createSagaMiddleware();

export default function configureReduxStore(preloadedState?: PreloadedState<RootState>) {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
      }).concat(sagaMiddleware)
  });

  return store;
}
